import { Component } from 'react';
import DnD from "utils/DnD";
import css from 'styled-jsx/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderBlank as folderIcon } from "@fortawesome/pro-solid-svg-icons/faFolderBlank";

const styles = css`
  .drag-layer {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		cursor: grabbing;
	}

	.preview {
		width: 30px;
		height: 30px;
		background-size: cover;
	}

	.preview.shadow {
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		background-color: black;
	}

	.preview :global(svg) {
		color: rgba(var(--accentColorDark), 1);
	}

	.label {
		font-size: 12px;
		background-color: rgba(var(--accentColor), 1);
		color: white;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 2px;
		padding-bottom: 2px;
		border-radius: 100px;
		margin-left: 5px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		max-width: 160px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 3000,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
}

function getItemStyles(position) {
	let size = 30;
	let { x, y } = position;

	x -= size/2;
	y -= size/2;

	const transform = `translate(${x}px, ${y}px)`
	const style = {
		height: size,
		transform,
		WebkitTransform: transform,
		backgroundRepeat: 'no-repeat',
	};

	return style;
}

class DragLayer extends Component {
	state = {
		isDragging: false,
		isAdd: false,
		position: {
			x: 0,
			y: 0
		},
		data: {}
	}

	onDragStart = (dragData) => {
		const allowedItemTypes = ['folder-item'];
		this.setState({
			isDragging: allowedItemTypes.includes(dragData.type),
			type: dragData.type,
			offset: dragData.offset,
			sourceData: dragData.sourceData
		});
		this.forceUpdate();
	}

	onDragMove = (data) => {
		this.setState({
			offset: data.offset,
			keyDown: data.keyDown,
			isCopy: (data.action==='copy' && data.canDrop()),
			sourceData: data.sourceData,
		});
		this.forceUpdate();
	}

	onDragEnd = () => {
		this.setState({isDragging: false});
	}

	componentDidMount() {
		DnD.on('dragStart', this.onDragStart);
		DnD.on('dragMove', this.onDragMove);
		DnD.on('dragEnd', this.onDragEnd);
	}

	componentWillUnmount() {
		DnD.off('dragStart', this.onDragStart);
		DnD.off('dragMove', this.onDragMove);
		DnD.off('dragEnd', this.onDragEnd);
	}

	render() {
		if(!this.state.isDragging || !this.state.sourceData || this.state.sourceData.showDragLayer===false)
			return null;

		let icon = null;
		switch(this.state.sourceData.source)
		{
			case "folder":
				icon = <FontAwesomeIcon icon={folderIcon} size="2x" />;
			break;
			default:
			break;
		}

		const style = {
			backgroundPosition: "center center",
		}

		if(this.state.sourceData.image!==undefined)
			style.backgroundImage = 'url('+this.state.sourceData.image+')';

		return (
			<div style={layerStyles} role="presentation">
				<div
          className="drag-layer"
          style={getItemStyles(this.state.offset)}
        >
          <div className={"preview" + (icon===null ? " shadow" : "")} style={style}>{icon}</div>
          <div className="label">{this.state.sourceData.name}</div>
        </div>
        <style jsx>{styles}</style>
			</div>
		)
	}
}


export default DragLayer;