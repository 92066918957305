import { useState, useRef, memo, useCallback } from 'react';
import LazyList from '@myalbum/lazylist2';
import FolderRow from './FolderRow';
import DragLayer from './DragLayer';
import style from './style';
import { Observer } from 'mobx-react';

function FolderView(props) {
  const [spacing] = useState(props.spacing);
  const labelSize = 30;
  const rows = useRef(null);
  const cacheKey = useRef(null);
  const itemSize = props.itemSize;

  const getRows = useCallback((contents, maxWidth, sort) => {
    if(maxWidth===0)
      return [];

    // Maak cacheKey aan, alleen rows updaten als deze data is gewijzigd
    const contentKeys = contents.map(item => item.name+'-'+item.key+'-'+item.type+'-'+item.subType).join(',');
    let _cacheKey = contentKeys+'-'+maxWidth + '-' + sort;

    if(cacheKey.current===_cacheKey)
      return rows.current;

    cacheKey.current = _cacheKey;


    const sortedContent = contents.slice().sort((a, b) => {

      // Terugknop fix
      if(a.subType!==undefined && a.subType==='parent')
        return -1;
      if(b.subType!==undefined && b.subType==='parent')
        return 1;

      switch(sort) {
        case 'name':
        default:
          return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})
        case 'date':
          return new Date(b.created) - new Date(a.created);
      }
    });
    
    const itemsPerRow = Math.floor((maxWidth + spacing) / (itemSize + spacing));
    const rowTypes = {folder: [], notFolder: []};
    for(let item of sortedContent) {
      const itemType = item.type==='folder' ? 'folder' : 'notFolder';
      if(rowTypes[itemType].length===0)
        rowTypes[itemType].push([]);

      let lastRow = rowTypes[itemType][rowTypes[itemType].length-1];
      if(lastRow.length===itemsPerRow)
      {
        rowTypes[itemType].push([]);
        lastRow = rowTypes[itemType][rowTypes[itemType].length-1];
      }

      lastRow.push(item);
    }

    // Folders bovenaan daarna alle niet-folders
    const myRows = [...rowTypes.folder, ...rowTypes.notFolder].map((row, index) => {
      const items = row;

      // Alle rows zelfde lengte graag, dus evt aanvullen met placeholders
      for(let a=items.length; a<itemsPerRow; a++)
      {
        items.push({
          key: items[0].type+'-placeholder-'+a,
          type: items[0].type+'-placeholder'
        });
      }

      const key = items.map(item => item.key).join('-');
      return {
        key: 'row-'+index+'-'+key,
        itemSize: {
          width: itemSize,
          height: row[0].type==='folder' ? 60 : itemSize+labelSize,
        },
        items,
      }
    });

    rows.current = myRows;
    return rows.current;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function renderRow(row) {
    return <FolderRow
      key={row.key}
      items={row.items}
      itemSize={row.itemSize}
      spacing={spacing}
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
      dragAndDrop={props.dragAndDrop}
    />;
  }

  const getItemLayout = useCallback((row) => {
    return {
      height: row.itemSize.height + spacing,
      width: 0,
    }
  }, [spacing]);

  return (
    <Observer>
    {
      () => {
        const rows = getRows(props.contents, props.width, props.sort);
        return (
          <div className="folder-view">
            <LazyList
              data={rows}
              name={props.name}
              renderItem={renderRow}
              estimatedItemSize={itemSize+labelSize}
              getItemLayout={getItemLayout}
              windowSize={3}
            />

            <DragLayer />
            <style jsx>{style}</style>
          </div>
        );
      }
    }
    </Observer>
  );
}

FolderView.defaultProps = {
  maxSize: 200,
  minItemsPerRow: 2,
  spacing: 15,
  sort: 'name-asc',
  dragAndDrop: false,
  onClick: () => {},
  onContextMenu: () => {},
  onContext: () => {},
}

export default memo(FolderView);