import {  action, observable, set, transaction, makeObservable } from "mobx";
import { propsToModel } from "utils/models";

export default class AlbumModel
{
  /** @type {import("./FolderModel").FolderModel} */
  parent;

  /** @type {string} */
  key;

  /** @type {string} */
  type = "album";

  /** @type {Boolean} */
  bookmark;

  /** @type {string} */
  title = "";

  /** @type {string} */
  name = "";

  /** @type {Object[]} */
  thumbs = [];

  /** @type {Date} */
  created;

  /** @type {Boolean} */
  legacy = false;

  /** @type {Date} */
  lastActivity;

  /** @type {Object} */
  links = {};

  /** @type {Object} */
  data = {};

  /**
   * @param {FolderModel} parent
   * @param {object} props
   */
  constructor(store, parent, props)
  {
    makeObservable(this, {
      name: observable,
      thumbs: observable,

      updateName: action,
      moveToFolder: action,
      delete: action,

      data: observable,
      setData: action
    });

    const {created, lastActivity,  ...rest} = props;

    propsToModel(rest, this);

    this.parent = parent;
    this.created = new Date(created);
    this.lastActivity = new Date(lastActivity);
    this.store = store;
  }

  /**
   * @param {Object} data
   * @param {string} data.name
   */
  updateLocal(data)
  {
    propsToModel(data, this, ['name', 'thumbs']);

    if(data.lastActivity)
      this.lastActivity = new Date(data.lastActivity);
  }

  /**
   * @param {Object} data
   * @return {[Promise, Function]}
   */
  updateRemote(data)
  {
    const [promise, cancel] = this.store.user.FetchJSON(this.links.update, {
      method: "POST",
      data
    });

    promise.then((response) =>
    {
      this.updateLocal(response.data);
    });

    return [promise, cancel];
  }

  /**
   * @param {string} name
   * @return {Promise}
   */
  updateName(name)
  {
    this.updateLocal({name})
    return [this.updateRemote({name})];
  }

  /**
   * @param {FolderModel} folder
   * @return {Promise}
   */
  moveToFolder(folder)
  {
    if(this.parent)
      this.parent.removeAlbum(this);

    return [this.updateRemote({parent: {key: folder.key}})];
  }

  /**
   * @returns {Promise}
   */
  delete()
  {
    this.parent.removeAlbum(this);

    return new Promise((resolve, reject) =>
    {
      const [promise] = this.store.user.FetchJSON(this.links.delete, {
        method: "DELETE"
      });
      promise.then(resolve).catch(reject);
    });
  }

  setData(data)
  {
    transaction(() => {
      for(let key in data) {
        set(this.data, key, data[key]);
      }
    });
  }
}