import { useEffect, useRef, useCallback } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { Observer, observer } from "mobx-react";
import MainMenuBar from "components/MainMenuBar/MainMenuBar";
import translationStore from "stores/translation-store";
import FolderItemStore from "models/contents/store";
import {getContextMenuItems, getParent, storeParents, fetchFolder, fetchTrending} from 'utils/folder';
import FolderView from 'components/Folder/View/FolderView';
import FolderToolbar from "components/Folder/Toolbar/FolderToolbar";
import TrendingAlbumView from 'components/TrendingAlbumView/TrendingAlbumView';
import TrialPopup from 'components/Promos/TrialPopup/TrialPopup';
import Footer from 'components/Footer/Footer';
import { useUser, createUserFromContext } from 'utils/user/lib';
import WelcomeToMyAlbum from 'components/WelcomeToMyAlbum/WelcomeToMyAlbum';
import styles from 'css/myalbums';
import useScrollRestoration from "hooks/useScrollRestoration";
import {fetchAccessForUser} from 'utils/access';
import NotFound from 'pages/404';
import Error from 'next/error'
import NoAccess from "components/NoAccess/NoAccess";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { reaction } from "mobx";
import LoginPage from "components/LoginPage/LoginPage";
import useFolderDimensions from "components/Folder/View/useFolderDimensions";
import NoSsr from "components/NoSsr";
import { NextSeo } from "next-seo";
import AppInstallBar from "components/AppInstallBar/AppInstallBar";
import { createTranslationsFromContext } from "utils/translations/lib";


function MyAlbumsPage(props) {
  const statusCode = props.statusCode;
  const slug = `folder-${props.folder?.key}`;
  const invite = props.invite;
  const user = useUser();
  const router = useRouter();

  useEffect(() => {
    const userStateDispose = reaction(
      () => user.isLoggedIn,
      () => {
        fetchAccessForUser(user, slug, invite)
        .then( access => {

          if(props.access.me.hasAccess && !access.me.hasAccess)
          {
            // We hadden toegang, maar nu  niet meer
            router.replace(router.asPath);
          }else if(!props.access.me.hasAccess && access.me.hasAccess)
          {
            // We hadden geen toegang maar nu wel
            router.replace(router.asPath);
          }

        });
      }
    );

    return () => {
      userStateDispose();
    }
  }, [props.access?.me?.hasAccess]); // eslint-disable-line react-hooks/exhaustive-deps

  switch(statusCode) {
    case 200:
    case 401:
      if(!props.access.me?.hasAccess)
      {
        if(props.folder.key==='root') {
          // MyAlbums page
          return (
            <LoginPage
              access={props.access}
              buttonLabel={translationStore.translate("myalbums.login-button-label")}
            />
          )
        }

        // Overige folders
        return (
          <NoAccess
            access={props.access}
            slug={slug}
            invite={invite}

            labels={[
              {
                icon: faLock,
                label: translationStore.translate(`access.${props.access.subject.accessLevel}.title`)
              },
              {
                icon: faUsers,
                label: translationStore.translate(`followers.title`, {count: props.access.subject.followerCount || 0})
              }
            ]}
          />
        )
      }else
      {
        return <Folder {...props} />
      }
    case 404:
      return <NotFound />;
    default:
      return <Error statusCode={statusCode} />
  }
}


function Folder(props)
{
  const user = useUser();
  const router = useRouter();
  const store = useRef(new FolderItemStore(user)).current;
  const isSearch = router.query.search && router.query.search.length > 0;
  const folder = props.folder.data ? store.getFolder(props.folder.data, null, {registerInStoreAsRoot: true}) : null;
  const trendingFolder = props.trending ? store.getTrending(props.trending) : null;
  const folderDimensions = useFolderDimensions();
  useScrollRestoration();

  function onContextMenu(folderItem) {
    return getContextMenuItems(folderItem);
  }

  const onCreateAlbum = useCallback(() => {
    router.push(`/myalbums/add?f=${folder.key}`);
  }, [router, folder.key]);

  const onSearch = useCallback((value) => {
    if(folder.key === 'root') {
      router.push(`/myalbums?search=${encodeURI(value)}`);
    } else {
      router.push(`/folder/${folder.key}?search=${encodeURI(value)}`);
    }
  }, [folder.key]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      store.dispose();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // store de parents van de kinderen in deze map,
    // slaat automatisch alleen data op van een folder waar we zelf niet de eigenaar van zijn
    if(!isSearch) {
      storeParents(folder);
    }
  }, [props.folder.key]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Observer>
    {
      () => {
        const title = folder && folder.key !== "root" ? `${folder.name} - ${translationStore.translate("myalbums.title")}` : translationStore.translate("myalbums.title");
        const folderContents = folder ? folder.contents.slice() : [];
        const sort = folder.settings.sortBy ? folder.settings.sortBy : user.preferences.get('folderSortBy');
        const showTrending = user.preferences.get('showTrending');

        let headerView = null;
        if(!isSearch) {
          if(folder.key === "root") {
            if(folderContents.length === 0) {
              headerView = 'welcome';
            } else {
              if(showTrending)
                headerView = 'trending';
            }
          }
        }

        if(folderContents.length===0)
        {
          // Lege map, toon album placeholders
          folderContents.push({
            key: 'empty-album',
            type: 'album-placeholder',
          })
        }

        return (<>
          <Head>
            <title>{title}</title>
          </Head>

          <MainMenuBar folder={folder} />
          <div style={{height: 65, backgroundColor: "white"}}></div>

          {
            folder.key !== "root" && (
              <>
                <NextSeo
                  title={props.access.subject.title}
                  description={props.access.subject.subtitle || " "}
                  openGraph={{
                    title: props.access.subject.title,
                    description: props.access.subject.subtitle || " ",
                    url: `${process.env.SITE_URL}/folder/${folder.key}/`,
                  }}
                />
                <AppInstallBar />
              </>
            )
          }

          <div
            className="top-bar"
          >
            <NoSsr>
              <FolderToolbar
                key={`folder-${folder.key}`}
                onCreateAlbum={onCreateAlbum}
                isSearch={isSearch}
                initialSearchValue={router.query.search}
                onSearch={onSearch}
                parent={getParent(folder)}
                width={folderDimensions.width}
                folder={folder}
              />
            </NoSsr>
          </div>

          {
            headerView==='trending' && trendingFolder &&
            <div
              className="top-bar bottom"
              style={{
                marginBottom: 30
              }}
            >
              <NoSsr>
                <TrendingAlbumView
                  folder={trendingFolder}
                  width={folderDimensions.width}
                  itemSize={folderDimensions.itemSize * 0.8}
                  onCreateAlbum={onCreateAlbum}
                />
              </NoSsr>
            </div>
          }
          
          {
            headerView==='welcome' && user.isLoggedIn &&
            <div className="top-bar bottom">
              <WelcomeToMyAlbum />
            </div>
          }

          <FolderView
            key={`folder-${folder.key}`}
            name={`folder-${folder.key}`}
            contents={folderContents}
            onContextMenu={onContextMenu}
            dragAndDrop={folder && folder.isOwner}
            sort={sort}
            width={folderDimensions.width}
            itemSize={folderDimensions.itemSize}
          />

          <TrialPopup />

          <Footer />
          <style jsx>{styles}</style>
        </>)
      }
    }
    </Observer>
  );
}

function stripThumbs(data) {
  return data.map(content => {
    if(content.item?.thumbs) {
      delete content.item.thumbs.landscape;
      delete content.item.thumbs.portrait;

      if(content.item.thumbs?.square)
      {
        const newSizes = content.item.thumbs?.square.sizes.filter(size => {
          if(size.width<450)
            return size;
        });

        if (newSizes.length > 0) {
          content.item.thumbs.square.sizes = newSizes;
        }
      }
    }

    return content;
  });
}

export default observer(MyAlbumsPage);
export async function getServerSideProps(context) {
  const key = context.query.folder !== undefined ? context.query.folder : "root";
  const slug = 'folder-'+key;
  const user = await createUserFromContext(context);
  const translations = await createTranslationsFromContext(context, user.account?.locale.language);
  const invite = context.query?.invite || null;

  let access;
  try {
    access = await fetchAccessForUser(user, slug, invite);
  }catch (e) {
    // Geen access, gooi maar over de schutting, rest is zinloos
    const statusCode = e.response.status!==undefined ? e.response.status : 500;
    context.res.statusCode = statusCode;
    return {props: {
      statusCode,
      user: user.JSON,
      translations: translations.JSON,
    }};
  }

  const props = {
    statusCode: 200,
    access,
    invite,
    folder: {
      hasAccess: false,
      key: key,
    },
    trending: null,
  };

  if(access.me.hasAccess)
  {
    if(context.query.search!==undefined && context.query.search.length>0) {
      const promises = [];
      promises.push(fetchFolder(user, key));
      promises.push(user.FetchJSON(`${process.env.API_URL}/myalbums/search/?query=${context.query.search}`)[0]);

      await Promise.all(promises)
      .then(([folder, search]) => {
        if(folder) {
          props.search = search.data.query;
          props.folder = folder;
          props.folder.data.me = {...access.me, ...props.folder.data.me};
          props.folder.data.contents = stripThumbs(search.data.contents);
        }
      })
      .catch(e => {
        // Errors, b.v. 404 doorpassen naar server
        if(e.statusCode!==200 && e.statusCode!==401)
          context.res.statusCode = e.statusCode;

        props.statusCode = e.statusCode;
      });
    } else  {
      const promises = [];
      promises.push(fetchFolder(user, key));

      if(key==='root') {
        promises.push(fetchTrending(user, key));
      }

      await Promise.all(promises)
      .then(([folder, trending]) => {
        if(folder) {
          props.folder = folder;
          props.folder.data.me = {...access.me, ...props.folder.data.me};
          props.folder.data.contents = stripThumbs(props.folder.data.contents);
        }

        if(trending) {
          props.trending = stripThumbs(trending);
        }
      })
      .catch(e => {
        // Errors, b.v. 404 doorpassen naar server
        if(e.statusCode!==200 && e.statusCode!==401)
          context.res.statusCode = e.statusCode;

        props.statusCode = e.statusCode;
      })
    }
  }else
  {
    // GEEN 403 anders gaat het uitloggen heel traag. ClientSide refresh wordt dan onmogelijk je 
    // forceert dan een reload van de browser
    context.res.statusCode = 200;
  }


  return {
    props: {
      ...props,
      user: user.JSON,
      translations: translations.JSON,
    }
  };
}