import { useCallback, useEffect, useState } from "react";
const isSSR = (typeof window === "undefined")

function useFolderDimensions() {
  const maxItemSize = 200;
  const minItemsPerRow = 2;
  const spacing = 15;
  const padding = 30;
    
  const getDimensions = useCallback(() => {
    const canvasWidth = isSSR ? 1200 : document.body.offsetWidth;
    const maxWidth = canvasWidth - (2*padding);
    const itemSize = Math.min(maxItemSize, (maxWidth-((minItemsPerRow-1) * spacing))/minItemsPerRow);
    const itemsPerRow = Math.floor((maxWidth + spacing) / (itemSize + spacing));
    const totalWidth = itemsPerRow * itemSize + ((itemsPerRow-1) * spacing);

    return {
      width: totalWidth,
      itemSize: itemSize,
    }
  }, []);

  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    function _setDimensions() {
      setDimensions(getDimensions());
    }
    window.addEventListener('resize', _setDimensions);
    _setDimensions();

    return () => {
      window.removeEventListener('resize', _setDimensions);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return dimensions;
}

export default useFolderDimensions;