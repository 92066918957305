import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react'
import Link from "next/link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { Menu } from 'components/UI';
import dnd from 'utils/DnD';
import translationStore from 'stores/translation-store';
import useLongPressContext from "hooks/useLongPressContext";
import {albumStyle} from './style';
import urlParse from 'url-parse';
const isSSR = (typeof window === "undefined");

function Album(props) {

  const style = {
    width: props.width,
    height: props.height,
    cursor: 'default',
    ...props.style,
  }

  const item = props.item ? props.item : {
    name: '',
    thumbs: [],
    type: 'placeholder',
    data: {
      isDragging: false
    }
  }

  const thumbs = item.thumbs;
  const ref = useRef();
  const previewRef = useRef();
  const [isMenuOpen, setMenuOpen] = Menu.useOpener();
  const [menuEvent, setMenuEvent] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const longPressContext = useLongPressContext({
    touchOnly: true,
  });


  useEffect(() =>
  {
    if(ref.current)
    {
      ref.current.addEventListener('contextmenu', onContext);

      const currentRef = ref.current;
      return () =>
      {
        if(currentRef)
          currentRef.removeEventListener('contextmenu', onContext);
      }
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  const onMenuClose = useCallback(() => {
    setMenuOpen(false)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onContext = e =>
  {
    e.preventDefault();
    setMenuItems(props.onContextMenu(props.item));
    setMenuEvent(e);
    setMenuOpen(true);
  }

  const getBestImage = () =>
  {
    const { width } = props;
    if(thumbs && Object.keys(thumbs).length > 0)
    {
      try
      {
        const { sizes } = thumbs.square;
        const pixelRatio = (!isSSR && "devicePixelRatio" in window) ? window.devicePixelRatio * 0.75 : 1;
        const targetSize = width * pixelRatio;

        let curSize = {width: 0, height: 0, url: null}
        for(let thumbSize of sizes) {
          // Thumb is ieg groter dan voorgaande bekende versie
          if(thumbSize.width>=curSize.width && thumbSize.height>=curSize.height)
            curSize = thumbSize;

          // Prima kandidaat: Thumb is groter of gelijk aan gewenste size
          if(thumbSize.width>=targetSize && thumbSize.height>=targetSize) {
            curSize = thumbSize;
            break;
          }
        }

        return curSize.url;
      }
      catch(e){
        return null;
      }
    }

    return null;
  }

  function addDnD(domNode) {
    const dragSource = dnd.addSource('folder-item', domNode, {
      minDragDistance: 10,
      canDrag: () => {
        return props.dragAndDrop;
      },
      beginDrag: () => {
        const returnValue = {
          source: "album",
          showDragLayer: true,
          autoScroll: true,
          image: getBestImage(),
          name: item.name.length>0 ? item.name : translationStore.translate('myalbums.nameless'),
        }

        // Niet lokaal bijhouden ivm lazylist unloads
        props.item.setData({isDragging: true});
        return returnValue;
      },
      endDrag: (result) => {
        if(result.didDrop) {
          const folder = result.targetData.folder;
          props.item.moveToFolder(folder);
        }

        // Niet lokaal bijhouden ivm lazylist unloads
        props.item.setData({isDragging: false});
      }
    });

    return function() {
      dragSource.destroy();
    }
  }

  React.useEffect(() => {
    return addDnD(previewRef.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [bestImage, setBestImage] = useState(null);
  useEffect(() => {
    setBestImage(getBestImage());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Observer>
    {
      () => {
        const name = item.name.length>0 ? item.name : translationStore.translate('myalbums.nameless');
        const previewStyle = {
          height: props.width,
        };
        const isDragging = props.item.data.isDragging && props.dragAndDrop;

        if(bestImage) {
          previewStyle.backgroundImage = `url(${bestImage})`
        }

        const classNames = ["album"];
        if(isDragging)
          classNames.push("is-dragging");

        return (
          <div className={classNames.join(' ')} style={style} ref={ref}>
            {item.bookmark && <div className="bookmark"><FontAwesomeIcon icon={faBookmark} size="2x" /></div>}
            <AlbumLink
              href={props.url}
              ref={previewRef}
              className="preview"
              draggable={false} // dit voorkomt native dragging van de link/image
              style={previewStyle}
            />
            <div
              className="label"
                style={{
                height: props.labelSize+'px',
              }}
            >
              <div className="normal" title={name} {...longPressContext}>{name}</div>
              {props.name2 && <div className="smaller">{props.name2}</div>}
            </div>

            <Menu
              isOpen={isMenuOpen}
              onRequestClose={onMenuClose}
              position={{
                event: menuEvent
              }}
            >
              {menuItems.length>0 && Menu.ArrayItems(menuItems)}
            </Menu>

            <style jsx>{albumStyle}</style>
          </div>
        )
      }
    }
    </Observer>
  );
}

const AlbumLink = forwardRef((props, forwardedRef) =>
{
  function isAbsoluteUrl()
  {
    const url = urlParse(props.href);
    return url.href === props.href;
  }

  if(isAbsoluteUrl())
  {
    return (
        <a ref={forwardedRef} onMouseDown={e => e.preventDefault()} {...props}>
          <style jsx>{albumStyle}</style>
        </a>
    );
  }

  const { href, ...rest } = props;

  return (
    <Link href={href} legacyBehavior>
      <a ref={forwardedRef} onMouseDown={e => e.preventDefault()} {...rest}>
        <style jsx>{albumStyle}</style>
      </a>
    </Link>
  );
})
AlbumLink.displayName = "AlbumLink";

Album.defaultProps = {
  dragAndDrop: false,
  style: {},
  name2: '',
  onContextMenu: () => { return [];}
}

export default Album;