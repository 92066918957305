import { useCallback, useRef, useState } from "react";
import { Observer } from "mobx-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { whenAuthenticated } from "components/Authentication";
import { Menu, Modal, SearchInput } from "components/UI";
import { faShareAlt } from "@fortawesome/pro-light-svg-icons/faShareAlt";
import { faEllipsisH } from "@fortawesome/pro-light-svg-icons/faEllipsisH";
import { faBookmark } from "@fortawesome/pro-light-svg-icons/faBookmark";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faBookmark as faBookmarkFilled } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import translationStore from "stores/translation-store";
import {useUser} from "utils/user/lib";
import styles from './style';
import ShareModal from "components/ShareModal/ShareModal";
import FolderModel from "models/contents/folder-model";
import FolderMoreModal from "../MoreModal/FolderMoreModal";
import MoreMenu from "./MoreMenu";
import BackLink from "./BackLink";


function FolderToolbar(props) {
  const {folder} = props;
  const user = useUser();
  const addBtnRef = useRef(null);
  const moreBtnRef = useRef(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isAddMenuOpen, setAddMenuOpen] = Menu.useOpener();
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const toggleBookmarkFolder= useCallback((folder) =>
  {
    whenAuthenticated(user, {
      message: translationStore.translate("folder.bookmark.logintext"),
    })
    .then(() =>
    {
      folder.updateBookmarked(!folder.isBookmarked);
    })
  }, [folder]); // eslint-disable-line react-hooks/exhaustive-deps

  const addFolder = useCallback(() => {
    Modal.Prompt({
      title: translationStore.translate('prompt.addfolder.title'),
      okButtonText: translationStore.translate('prompt.addfolder.okbutton'),
      inputs: [
        {
          type: "text",
          name: "name",
          value: "",
          autoFocus: true,
          autoComplete: "false",
          okOnEnter: true,
          style: {flex: 1}
        }
      ]
    })
    .then((values) => {
      if(values.name.length>0) {
        FolderModel.create(folder.store, folder, {
          name: values.name
        })
        .then(newFolder =>
        {
          folder.addFolder(newFolder);
        })
      }
    })
    .catch(() => {})
  }, [folder]);

  const showSettings = useCallback(() => {
    if(folder.key==='root')
    {
      setShowMoreMenu(true);
    }else
      setSettingsVisible(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const closeSettings = useCallback(() => {
    setSettingsVisible(false);
  }, []);

  return (
    <Observer>
    {
      () => {
        let pageTitle = folder ? (folder.name.length>0 ? folder.name : '\u00A0') : '\u00A0';
        if(props.isSearch) {
          pageTitle = translationStore.translate('base.search-results');
        }
        
        return (
          <div className="folder-bar"
            style={{
              width: props.width,
            }}
          >
            <BackLink isSearch={props.isSearch} folder={props.folder} parent={props.parent} />

            <div className="row">
              <div className="left">
                <h1>{pageTitle}</h1>
              </div>
              <div className="right" style={{
                visibility: props.width>0 ? 'visible' : 'hidden',
              }}>
                {
                  folder && folder.canAdd && !props.isSearch &&
                  <button className="round-button with-label accent" onClick={() => setAddMenuOpen(true)} ref={addBtnRef}><FontAwesomeIcon icon={faPlus} />{translationStore.translate('myalbums.add.option-new.title')}</button>
                }
                {
                  folder && folder.canShare && !props.isSearch &&
                  <button className="round-button" onClick={() => setIsShareModalOpen(true)}><FontAwesomeIcon icon={faShareAlt} /></button>
                }
                {
                  folder && folder.canBookmark && !props.isSearch &&
                  <button className="round-button" onClick={() => toggleBookmarkFolder(folder)}><FontAwesomeIcon color={folder.isBookmarked ? 'rgba(var(--accentColor), 1)' : 'rgba(var(--accentColorDark), 1)'} icon={folder.isBookmarked ? faBookmarkFilled : faBookmark} style={{width: 15, height: 15}} /></button>
                }
                {
                  folder && (folder.me.role==='owner' || folder.me.role==='member') && !props.isSearch &&
                  <button className="round-button" onClick={showSettings} ref={moreBtnRef}><FontAwesomeIcon style={{height: 21, width: 18}} icon={faEllipsisH} /></button>
                }
                {
                  folder && (folder.me.role==='owner' || folder.me.role==='member') &&
                  (
                    <SearchInput
                      value={props.initialSearchValue}
                      placeholder={translationStore.translate('base.search')}
                      onChange={props.onSearch}
                    />
                  )
                }
              </div>
            </div>
            
            {
              isShareModalOpen && <ShareModal
                onClose={() => setIsShareModalOpen(false)}
                subject={{
                  key: folder.key,
                  type: 'folder',
                }}
              />
            }

            <Menu
              position={{ref: addBtnRef, hAlign: "right", vAlign: "bottom", y: 10, x: -2}}
              isOpen={isAddMenuOpen}
              onRequestClose={() => setAddMenuOpen(false)}
            >
              <Menu.Item
                title={translationStore.translate("myalbums.context.add-album")}
                onClick={props.onCreateAlbum}
              />
              <Menu.Item
                title={translationStore.translate("myalbums.context.add-folder")}
                onClick={addFolder}
              />
            </Menu>

            {
              settingsVisible && (
                <FolderMoreModal
                  folder={folder.key}
                  onRename={name => {
                    folder.updateLocal({
                      name
                    })
                  }}
                  onUpdateSetting={(key, value) => {
                    const settings = {};
                    settings[key] = value;

                    folder.updateLocal({settings});
                  }}
                  onClose={closeSettings}
                />
              )
            }

            <MoreMenu
              folder={folder}
              position={{ref: moreBtnRef, hAlign: "right", vAlign: "bottom", y: 10, x: -2}}
              onRequestClose={() => setShowMoreMenu(false)}
              isOpen={showMoreMenu}
            />

            <style jsx>{styles}</style>
          </div>
        )
      }
    }
    </Observer>
  );
}

export default FolderToolbar;