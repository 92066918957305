import css from 'styled-jsx/css'

const style = css`
  .search-input {
    background-color: white;
    flex: 1;
    height: 40px;
    border-radius: 20px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: inline-flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 50px;
    max-width: 400px;
  }

  .search-input .value {
    border: 0px;
    background-color: transparent;
    -webkit-appearance: none;
    height: 40px;
    display: inline-block;
    flex: 1;
    padding: 0;
    margin: 0;
    padding-left: 35px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
    border-radius: 20px;
    border: 1px solid rgba(var(--buttonBorder), 1);
    outline: none;
    width: 100%;
  }

  .search-input:focus-within .value {
    border-color: rgba(var(--accentColor), 1);
  }

  .search-input .value:focus+[type=submit] {
    outline: none;
    top: 0px;
    bottom: 0px;
  }

  .search-input .icon {
    color: rgba(var(--accentColorMedium), 1);
    position: absolute;
    margin-left: 15px;
    margin-top: 2px;
    pointer-events: none;
  }

  .search-input [type=submit] :global(svg) {
    width: 15px !important;
    height: 15px !important;
    color: rgba(var(--accentColorMedium), 1);
  }
`;

export default style;