import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react'
import Link from "next/link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'components/UI';
import { faBookmark } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { faFolderBlank as folderIcon } from "@fortawesome/pro-solid-svg-icons/faFolderBlank";
import { faLevelUpAlt } from "@fortawesome/pro-solid-svg-icons/faLevelUpAlt";
import {folderStyle} from './style';
import dnd from 'utils/DnD';

function Folder(props) {
  const ref = useRef(null);
  const [isOver, setIsOver] = useState(false);

  const [isMenuOpen, setMenuOpen] = Menu.useOpener();
  const [menuEvent, setMenuEvent] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const style = {
    width: props.width,
    height: props.height,
    backgroundColor: isOver ? 'rgba(var(--accentColorDark), 0.3)' : 'transparent',
    borderColor: isOver ? 'rgba(var(--accentColorDark), 0.5)' : 'rgba(var(--accentColorLight), 1)',
    ...props.style
  }

  useEffect(() =>
  {
    if(ref.current)
    {
      ref.current.addEventListener('contextmenu', onContext);

      const currentRef = ref.current;
      return () =>
      {
        if(currentRef)
          currentRef.removeEventListener('contextmenu', onContext);
      }
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  const onMenuClose = useCallback(() => {
    setMenuOpen(false)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onContext = e =>
  {
    e.preventDefault();

    setMenuItems(props.onContextMenu(props.item));
    setMenuEvent(e);
    setMenuOpen(true);
  }

  function addDnD(domNode) {
    const dragTarget = dnd.addTarget('folder-item', domNode, {
      isOver: (state) => {
        if(!props.item.bookmark)
          setIsOver(state);
      },
      canDrop: () => {
        if(props.item.bookmark)
          return false;

        return props.dragAndDrop;
      },
      drop: () => {
        const returnValue = {
          dropped: true,
          folder: props.item,
        };

        return returnValue;
      }
    });

    const dragSource = dnd.addSource('folder-item', domNode, {
      minDragDistance: 10,
      canDrag: () => {
        if(props.item.subType!==undefined && props.item.subType==='parent')
          return false;

        return props.dragAndDrop;
      },
      beginDrag: () => {
        const returnValue = {
          source: "folder",
          showDragLayer: true,
          autoScroll: true,
          name: props.item.name,
        }

        props.item.setData({isDragging: true});
        return returnValue;
      },
      endDrag: (result) => {
        if(result.didDrop) {
          const folder = result.targetData.folder;
          props.item.moveToFolder(folder);
        }

        props.item.setData({isDragging: false});
      }
    });

    return function() {
      dragTarget.destroy();
      dragSource.destroy();
    }
  }

  React.useEffect(() => {
    return addDnD(ref.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Observer>
    {
      () => {
        let icon;
        if(props.item.subType!==undefined && props.item.subType==='parent')
          icon = <FontAwesomeIcon icon={faLevelUpAlt} size="1x" rotation={270} />;
        else
          icon = <FontAwesomeIcon icon={folderIcon} size="2x" />;

        const classNames = ["folder"];
        if(props.item.data && props.item.data.isDragging)
          classNames.push("is-dragging");

        if(props.item.subType!==undefined)
          classNames.push("subtype-"+props.item.subType);

        if(props.item.bookmark)
        {
          classNames.push('bookmark');
          icon = <FontAwesomeIcon icon={faBookmark} size="2x" />;
        }

        return <>
          <div
            ref={ref}
            className={classNames.join(" ")}
            style={style}
          >
            <Link
              href={props.url}
              legacyBehavior
            >
              <a
                 // dit voorkomt native dragging van de link/image
                onMouseDown={e => e.preventDefault()}
              >
                {icon}
                <div className="label">{props.item.name}</div>
              </a>
            </Link>

            <style jsx>{folderStyle}</style>
          </div>

          <Menu
            isOpen={isMenuOpen}
            onRequestClose={onMenuClose}
            position={{
              event: menuEvent
            }}
          >
            { menuItems.length>0 && Menu.ArrayItems(menuItems)}
          </Menu>
        </>;
      }
    }
    </Observer>
  );
}

Folder.defaultProps = {
  style: {},
  onContextMenu: () => { return [];}
}

export default Folder;