import { Menu } from "components/UI";
import { observer } from "mobx-react";
import translationStore from "stores/translation-store";
import { useUser } from "utils/user/lib";

function MoreMenu(props) {
  const {isOpen} = props;
  const user = useUser();
  const folderSortBy = user.preferences.get('folderSortBy');
  const showTrending = user.preferences.get('showTrending');
  const folderItems = [];

  if(isOpen) 
  {  
    // SORTING
    folderItems.push(
      <Menu.Title key="sort-title" title={translationStore.translate('menubar.sorting-label').toUpperCase()} />
    )
    folderItems.push(
      <Menu.Check
        key="sort-name"
        title={translationStore.translate("myalbums.sort.name")}
        onClick={() => {
          user.setPreference("folderSortBy", "name");
        }}
        checked={folderSortBy==='name'}
      />
    );
    folderItems.push(
      <Menu.Check
        key="sort-date"
        title={translationStore.translate("myalbums.sort.date")}
        onClick={() => {
          user.setPreference("folderSortBy", "date");
        }}
        checked={folderSortBy==='date'}
      />
    );

    if(props.folder.key==='root')
    {
      folderItems.push(
        <Menu.Separator key="rending-separator" />
      )
      folderItems.push(
        <Menu.Check
          key="show-trending"
          title={translationStore.translate("myalbums.show-trending")}
          onClick={() => {
            user.setPreference("showTrending", !showTrending);
          }}
          checked={showTrending}
        />
      );
    }
  }

  return (
    <Menu
      key="menu"
      position={props.position}
      isOpen={isOpen}
      onRequestClose={props.onRequestClose}
    >
      {folderItems}
    </Menu>
  )
}

export default observer(MoreMenu);