import { Observer } from 'mobx-react'
import translationStore from "stores/translation-store";
import { useUser } from "utils/user/lib";

function WelcomeToMyAlbum() {
  const user = useUser();

  return (
    <Observer>
    {
      () => (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            height: '80%',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div style={{
            fontSize: '4.5rem',
            fontWeight: 400,
            color: 'rgba(var(--accentColorDark), 1)',
          }}>
            {translationStore.translate('myalbums.empty.title', {firstName: user.firstName})}
          </div>
          <div style={{
            marginTop: 10,
            maxWidth: 500,
            fontWeight: 300,
            fontFamily: "Roboto Slab",
          }}>
            {
              translationStore.translate('myalbums.empty.text.'+user.subscription.type, {}, {markdown: true})
            }
          </div>
        </div>
      )
    }
    </Observer>
  );
}

export default WelcomeToMyAlbum;