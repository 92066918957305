import Album from './Album';
import Folder from './Folder';

function FolderRow(props) {
  const labelSize = 30;

  return (
    <div className="row" style={{
      marginBottom: props.spacing,
      height: props.itemSize.height,
    }}>
    {
      props.items.map((item, index) => {
        let content = null;
        let marginRight = (index<props.items.length-1) ? props.spacing : 0;

        switch(item.type) {
          case "folder":
            const folderUrl = item.key==='root' ? '/myalbums/' : `/folder/${encodeURIComponent(item.key)}`;
            content = <Folder
              key={item.key}
              width={props.itemSize.width}
              height={props.itemSize.height}
              style={{marginRight: marginRight}}
              url={folderUrl}
              onContextMenu={props.onContextMenu}
              dragAndDrop={props.dragAndDrop}
              item={item}
            />;
          break;
          case "folder-placeholder":
            content = <div
              key={item.key}
              className="folder-placeholder"
              style={{
                width: props.itemSize.width,
                height: props.itemSize.height,
                marginRight: marginRight,
              }}
            />;
          break;
          case "bookmark":
          case "album":
            content = <Album
              key={`${item.type}-${item.key}`}
              width={props.itemSize.width}
              height={props.itemSize.height}
              labelSize={labelSize}
              style={{marginRight: marginRight}}
              url={`/album/${item.key}`}
              onContextMenu={props.onContextMenu}
              dragAndDrop={props.dragAndDrop}
              item={item}
            />
          break;
          case "bookmark-placeholder":
          case "album-placeholder":
          case "album-placeholder-placeholder":
            content = <div
              key={item.key}
              className={"album-placeholder " + (item.className!==undefined ? item.className : "")}
              style={{
                width: props.itemSize.width,
                height: props.itemSize.height,
                marginRight: marginRight,
              }}
            >
              <div className="preview"></div>
            </div>;
          break;
        }

        return content;
      })
    }
    </div>
  )
}
export default FolderRow;