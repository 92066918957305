import { action, computed, extendObservable, observable, remove, set, transaction, makeObservable } from "mobx";
import translationStore from "stores/translation-store";
import { propsToModel } from "utils/models";

/**
 * @param {import("./store").default} store
 * @param {Object} content
 * @param {FolderModel} parent
 */
function getModelFromContent(store, content, parent)
{
  switch(content.type)
  {
    case 'folder':
      return store.getFolder({...content.item, bookmark: content.bookmark}, parent);
    case 'album':
      return store.getAlbum(parent, {...content.item, bookmark: content.bookmark, legacy: content.legacy});
    default:
      console.warn(`Invalid content type: '${content.type}'`);
  }
}

/**
 * @typedef {Object} AvatarSize
 * @property {string} url
 * @property {number} width
 * @property {number} height
 */

/**
 * @typedef {Object} Avatar
 * @property {string} name
 * @property {string} initials
 * @property {string} color
 * @property {Object} image
 * @property {AvatarSize[]} image.sizes
 */

export default class FolderModel
{
  /** @type {string} */
  key;

  /** @type {string} */
  type = "folder";

  /** @type {Boolean} */
  _bookmark;

  /** @type {string} */
  _name = "";

  /** @type {Date} */
  created;

  /** @type {FolderModel} */
  parent;

  /** @type {{showAvatar: Boolean, showShareOptions: Boolean, sortBy: String}} */
  settings = {};

  /** @type {Array<AlbumModel|FolderModel>} */
  contents = [];

  /** @type {{avatar: Avatar}} */
  owner;

  /** @type {{role: 'guest'|'member'|'owner', bookmarked: Boolean}} */
  me = {
    role: 'guest',
    bookmarked: false
  };

  /** @type {Object} */
  links = {};

  /** @type {Object} */
  data = {};

  /** @type {import("./store").default} */
  store;

  /**
   * @param {import("./store").default} store
   * @param {Object} props
   * @param {string} props.key
   * @param {string} props.name
   * @param {string} props.created ISO time
   * @param {Object} props.parent
   * @param {Boolean} props.bookmark
   * @param {Array} props.contents
   * @param {Object} props.links
   * @param {FolderModel} parentFolder
   * @param {Object} config
   * @param {Boolean} config.registerInStoreAsRoot
   */
  constructor(store, props, parentFolder, config = {})
  {
    makeObservable(this, {
      _name: observable,
      name: computed,
      _bookmark: observable,
      bookmark: computed,
      parent: observable,
      settings: observable,
      contents: observable,
      owner: observable,
      me: observable,

      isOwner: computed,
      isBookmarked: computed,
      canAdd: computed({keepAlive: true}),
      canShare: computed,
      canBookmark: computed,

      updateMe: action,
      updateName: action,
      updateSetting: action,

      updateLocal: action,
      updateRemote: action,

      addFolder: action,
      moveToFolder: action,
      updateBookmarked: action,
      delete: action,
      removeAlbum: action,
      removeFolder: action,

      data: observable,
      setData: action
    });

    if(!props)
      return;

    const {created, parent, contents, me, ...rest} = props;

    propsToModel(rest, this);

    this.created = new Date(created);

    if(parent && !parentFolder)
      this.parent = store.getFolder(parent);
    else if (parentFolder)
      this.parent = parentFolder;

    if(contents)
      this.contents = contents.map(item => getModelFromContent(store, item, this));

    if(me)
      this.me = me;

    this.store = store;

    if(config.registerInStoreAsRoot)
      this.store.registerModel(this);
  }

  /**
   * @param {string} value
   */
  set name(value) {
    // bookmarks hebben de name in de me staan
    if(this.isBookmarked)
      this.me.name = value;
    else
      this._name = value;
  }

  /**
   * @returns {string}
   */
  get name() {
    if(this.key === "root")
      return translationStore.translate("myalbums.title");

    // Bookmark hebben de name in de me staan
    if(this.isBookmarked && this.me.name!==undefined && this.me.name.length>0)
      return this.me.name;

    return this._name;
  }

  /**
   * @param {Boolean} value
   */
  set bookmark(value)
  {
    this._bookmark = value;
  }

  get bookmark()
  {
    if(this._bookmark !== undefined)
      return !!this._bookmark;

    return false;
  }

  /** 
   * @returns {Boolean}
   */
  get isOwner()
  {
    if(this.key === "root" || this.key==="trending")
      return true;

    return this.me.role === "owner";
  }

  get isBookmarked()
  {
    return this.me.bookmarked || false;
  }

  /** 
   * @returns {Boolean}
   */
  get canAdd()
  {
    return this.isOwner;
  }

  /** 
   * @returns {Boolean}
   */
  get canShare()
  {
    return this.key !== "root" && (this.settings.showShareOptions || this.isOwner);
  }

  /** 
   * @returns {Boolean}
   */
  get canBookmark()
  {
    return this.key !== "root" && !this.isOwner;
  }

  /**
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.me
   * @param {string} data.me.role
   * @param {string} data.me.bookmarked
   * @param {string} data.settings.showAvatar
   * @param {string} data.settings.showShareOptions
   */
  updateLocal(data)
  {
    propsToModel(data, this, ['name', 'settings', 'me']);
  }

  /**
   * @param {Object} data
   * @return {[Promise, Function]}
   */
  updateRemote(data)
  {
    const updateLink = this.me.links!==undefined && this.me.links.update!==undefined ? this.me.links.update : this.links.update;
    const [promise, cancel] = this.store.user.FetchJSON(updateLink, {
      method: "POST",
      data
    });

    promise.then((response) =>
    {
      this.updateLocal(response.data);
    });

    return [promise, cancel];
  }

  /**
   * @return {Promise}
   */
  updateMe()
  {
    if(!this.links.me)
      return Promise.resolve();

    return new Promise((resolve, reject) =>
    {
      const [promise] = this.store.user.FetchJSON(this.links.me)
      promise.then(result =>
      {
        transaction(() =>
        {
          for(let key in this.me)
            remove(this.me, key);
          
          extendObservable(this.me, result.data);
        })
        resolve();
      })
      .catch(reject)
    })
  }

  /**
   * @param {string} name
   * @return {Promise}
   */
  updateName(name)
  {
    this.updateLocal({name})
    return [this.updateRemote({name})];
  }

  /**
   * @param {Folder} folder
   */
  addFolder(folder)
  {
    this.contents.push(folder);
  }

  /**
   * @param {FolderModel} folder
   * @return {Promise}
   */
  moveToFolder(folder)
  {
    if(this.parent)
      this.parent.removeFolder(this);

    return [this.updateRemote({parent: {key: folder.key}})];
  }

  /**
   * @return {Promise}
   */
  delete()
  {
    return new Promise((resolve, reject) =>
    {
      const [promise] = this.store.user.FetchJSON(this.links.delete, {
        method: "DELETE"
      });
      promise.then(() => {
        if(this.parent)
          this.parent.removeFolder(this);

        resolve();
      })
      .catch(reject);
    })
  }

  /**
   * @param {AlbumModel} Album
   */
  removeAlbum(album)
  {
    this.store.deleteAlbum(album);
  }

  /**
   * @param {FolderModel} folder
   */
  removeFolder(folder)
  {
    this.store.deleteFolder(folder);
  }

  /**
   * @param {Boolean} state
   */
  updateBookmarked(state)
  {
    if(this.isOwner)
      return Promise.resolve();

    this.updateLocal({me: {...this.me, bookmarked: state}});

    return new Promise((resolve, reject) =>
    {
      const method = state ? "POST" : "DELETE";
      const [promise] = this.store.user.FetchJSON(this.links.bookmark, {
        method
      });
      promise.then(() =>
      {
        this.updateMe().then(() =>
        {
          resolve();
        });
      }).catch(reject)
    })
  }

  updateSetting(key, value)
  {
    const nextSettings = {...this.settings};
    nextSettings[key] = value;

    this.updateLocal({settings: nextSettings});
    return this.updateRemote({settings: nextSettings});
  }

  setData(data)
  {
    transaction(() => {
      for(let key in data) {
        set(this.data, key, data[key]);
      }
    });
  }

  /**
   * @param {FolderModel} parent
   * @param {object} props
   * @param {string} props.name
   * @returns {Promise<FolderModel>}
   */
  static create(store, parent, props)
  {
    return new Promise((resolve, reject) =>
    {
      const [promise] = store.user.FetchJSON(`${process.env.API_URL}/folder/`, {
        method: 'POST',
        data: {
          name: props.name,
          parent: {
            key: parent.key
          }
        }
      });

      promise.then(result =>
      {
        resolve(store.getFolder(result.data, parent));
      }).catch(reject);
    })
  }
}