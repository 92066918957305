
import { useRef, useCallback, useMemo } from 'react';

export default function useLongPressContext({
  touchOnly = false,
  ms = 300,
} = {}) {
  const timerRef = useRef(false);
  const eventRef = useRef({});

  const callback = useCallback(() => {
    const position = (eventRef.current.touches && eventRef.current.touches.length>0) ? {
      y: eventRef.current.touches[0].clientY + 5,
      x: eventRef.current.touches[0].clientX - 3,
    } : {
      y: eventRef.current.clientY,
      x: eventRef.current.clientX,
    };

    const event = new MouseEvent('contextmenu', {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: position.x,
      clientY: position.y,
    });
    eventRef.current.target.dispatchEvent(event);

    eventRef.current = {};
    timerRef.current = false;
  }, []);

  const start = useCallback(
    (ev) => {
      ev.persist();
      eventRef.current = ev;
      timerRef.current = setTimeout(callback, ms);
    },
    [callback, ms]
  );

  const stop = useCallback(
    (ev) => {
      ev.persist();
      eventRef.current = ev;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = false;
        eventRef.current = {};
      }
    },
    []
  );

  return useMemo(() => {
    if(touchOnly) {
      return {
        onTouchMove: stop,
        onTouchStart: start,
        onTouchEnd: stop,
      }
    } else {
      return {
        onMouseDown: start,
        onMouseUp: stop,
        onMouseMove: stop,
        onTouchMove: stop,
        onTouchStart: start,
        onTouchEnd: stop,
      }
    }
  }, [start, stop]); // eslint-disable-line react-hooks/exhaustive-deps
}