import translationStore from 'stores/translation-store';
import { Modal } from 'components/UI';
import { runInAction } from 'mobx';
import Router from 'next/router'
const isSSR = (typeof window === "undefined")


function onDelete(item)
{
  return new Promise(resolve => {
    const itemType = item.type === "folder" ? "folder" : item.bookmark ? "bookmark" : "album";

    Modal.Confirm({
      title: translationStore.translate("base.remove"),
      description: translationStore.translate(`folderview.remove-${itemType}.description`, {name: item.name}),
      okButtonText: translationStore.translate("base.remove"),
      cancelButtonText: translationStore.translate("base.cancel")
    })
    .then(() =>
    {
      item.delete()
      .then(() => {
        resolve(true);
      })
      .catch(e =>
      {
        let description;
        switch(e.response?.data?.error) {
          case "folder-not-empty":
            description = translationStore.translate('error.delete.folder-not-empty');
          break;
          default:
            description = e.response.data.error;
          break;
        }

        Modal.Alert({
          title: translationStore.translate("base.error"),
          description: description
        });

        resolve(false);
      });
    })
    .catch(() => {
      resolve(false);
    });
  });
}

function onRename(item) {
  Modal.Prompt({
    title: translationStore.translate(`prompt.rename-${item.type}.title`),
    okButtonText: translationStore.translate(`base.rename`),
    inputs: [
      {
        type: "text",
        name: "name",
        value: item.name,
        autoFocus: true,
        autoComplete: "false",
        okOnEnter: true,
        style: {flex: 1}
      }
    ]
  })
  .then((values) => {
    if(values.name.length>0) {
      item.updateName(values.name)
    }
  })
  .catch(() => {})
}

function onConvert(item) {
  Modal.Confirm({
    title: translationStore.translate("modal.convert-legacyalbum.title"),
    description: translationStore.translate(`modal.convert-legacyalbum.description`, {name: item.name}),
    okButtonText: translationStore.translate("base.convert"),
    cancelButtonText: translationStore.translate("base.cancel")
  })
  .then(() =>
  {
    convertAlbum(item);
  })
  .catch(() => {});
}

function convertAlbum(album)
{
  if(album.data.isConverting)
    return;

  album.setData({isConverting: true});
  const loader = Modal.Loader({
    message: translationStore.translate("modal.convert-legacyalbum.converting-text"),
  });

  const [promise] = album.store.user.FetchJSON(process.env.API_URL + "/import/" + album.key);
  promise.then(res =>
  {
    checkImported(album.store.user, res.data.albumKey)
    .then(() =>
    {
      album.setData({isConverting: false});
      loader.close();
      Router.replace(Router.asPath);
    });
  })
  .catch((e) => {
    Modal.Alert({
      title: `Error: ${e.statusCode} ${e.msg}`,
      description: e.response.data.error
    });

    album.setData({isConverting: false});
    console.error(e);
    loader.close();
  })
}

function checkImported(user, albumKey)
{
  const _checkImported = (albumKey, resolve) =>
  {
    setTimeout(() =>
    {
      const [promise] = user.FetchJSON(process.env.API_URL + "/album/" + albumKey);
      promise.then(res =>
      {
        if(Array.isArray(res.data.contents) && res.data.contents.length > 0) {
          setTimeout(() => {
            resolve();
          }, 1000);
        } else {
          _checkImported(albumKey, resolve);
        }
      }).catch(() => _checkImported(albumKey, resolve));
    }, 1000);
  }

  return new Promise(resolve =>
  {
    _checkImported(albumKey, resolve);
  })
}


function getContextMenuItems(folderItem) {
  let result = [];
  switch(folderItem.type) {
    case "album":
    case "bookmark":
      if(!folderItem.parent || folderItem.parent.isOwner)
      {
        result.push({
          type: "item",
          title: translationStore.translate("base.rename"),
          onClick: () => {
            onRename(folderItem);
          }
        });

        result.push({type: 'separator'});

        result.push({
          type: "item",
          title: translationStore.translate("base.delete"),
          destructive: true,
          onClick: () => {
            onDelete(folderItem);
          }
        });

        if(!folderItem.bookmark && folderItem.legacy && parseInt(process.env.ALBUMCONVERT) === 1) {
          result.push({
            type: 'separator',
          });

          result.push({
            type: "item",
            title: translationStore.translate("context.convert-and-duplicate-to-v4"),
            onClick: () => {
              onConvert(folderItem);
            }
          });
        }
      }
    break;
    case "folder":
      if(folderItem.subType!==undefined && folderItem.subType==='parent')
        return [];

      if(!folderItem.parent || folderItem.parent.isOwner)
      {
        result.push({
          type: "item",
          title: translationStore.translate("base.rename"),
          onClick: () => {
            onRename(folderItem);
          }
        });

        result.push({type: 'separator'});

        result.push({
          type: "item",
          title: translationStore.translate("base.delete"),
          destructive: true,
          onClick: () => {
            onDelete(folderItem);
          }
        });
      }
    break;
    default:
    break;
  }

  return result;
}

// Sla voor de kinderen van deze folder de parent op, indien nodig
// we slaan de parent alleen in de sessionStorage op als we zelf niet de eigenaar van de map zijn
function storeParents(folderItem) {
  if(isSSR || !folderItem)
    return;

  runInAction(() => {
    // ivm gesharede folders slaan we een lijst van albums en welke parent daarbij hoort op
    for(let item of folderItem.contents) {
      window.sessionStorage.setItem("ps-"+item.key, JSON.stringify({parent: {key: folderItem.key, name: folderItem.name}}));
    }
  });
}

// Haalt parent van dit item op
// als het item zelf geen parent heeft kijken we in de sessionStorage
// hierin kunnen we de parents terugvinden van mappen die niet van ons zijn
// maar die we wel een keer hebben bekeken
function getParent(folderItem) {
  if(!folderItem)
    return;

  if(!isSSR)
  {
    const item = window.sessionStorage.getItem("ps-"+folderItem.key);
    if(item)
    {
      try {
        const obj = JSON.parse(item);
        return obj.parent;
      }catch {
        //
      }
    }
  }

  if(folderItem.parent)
  {
    // Als parent root is moet je ingelogd zijn
    if(folderItem.parent.key==='root' && !folderItem.store.user.isLoggedIn)
      return;

    return folderItem.parent;
  }
}

function fetchFolder(user, key) {
  return new Promise(resolve => {
    const fetchPromise = new Promise((resolve, reject) =>
    {
      const emptyKeys = [undefined, null, "", "root"];
      const isRoot = emptyKeys.includes(key);
      const url = isRoot ? `${process.env.API_URL}/myalbums/` : `${process.env.API_URL}/folder/${key}`;

      const [promise] = user.FetchJSON(url);
      promise.then(({data}) =>
      {
        if(!isRoot)
        {
          const [mePromise] = user.FetchJSON(data.links.me);
          mePromise.then(me =>
          {
            resolve({
              ...data,
              me: me.data,
            });
          })
        }
        else
          resolve(data);
      })
      .catch(reject);
    });

    fetchPromise.then(data => {

      resolve({
        data: data,
        key: key,
        hasAccess: true,
      });
    })
    .catch((e) => {
      // Of login nodig, of access key nodig
      // hoe dan ook, je kan er nu niet bij

      resolve({
        data: null,
        key: key,
        hasAccess: (e.statusCode!==401),
      });
    });
  });
}

function fetchTrending(user) {
  return new Promise(resolve => {
    if(!user.isLoggedIn)
      return resolve([]);

    const [promise] = user.FetchJSON(`${process.env.API_URL}/myalbums/trending`);
    promise.then(({data}) =>
    {
      resolve(data.contents);
    })
    .catch(() =>
    {
      resolve([]);
    });
  });
}

export {
  getContextMenuItems,
  storeParents,
  getParent,
  fetchFolder,
  fetchTrending,
}