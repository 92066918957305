import { useState, memo, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";

import style from './style';

function SearchInput(props) {
  const [value, setValue] = useState(props.value);
  const ref = useRef();

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onSearch = useCallback((e) => {
    e.preventDefault();

    const isSmallScreen = (window.innerWidth < 550);
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    if(supportsTouch && isSmallScreen) {
      // Op touch device ruimte maken door keyboard te hiden
      requestAnimationFrame(() => {
        document.activeElement.blur();
      });
    }

    setValue(ref.current.value);
    props.onChange(ref.current.value);
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="search-input" style={{width: props.width}}>
      <div className="icon"><FontAwesomeIcon icon={faSearch} /></div>
      
      <form onSubmit={onSearch}>
        <input
          ref={ref}
          type='search'
          className="value"
          name='q'
          placeholder={props.placeholder}
          enterKeyHint="search"
          value={value}
          autoFocus={props.autoFocus}
          onChange={onChange}
        />
        <input type='submit' style={{position: 'absolute', left: -9999, width: 1, height: 1}} />
      </form>
      <style jsx>{style}</style>
    </div>
  )
}

SearchInput.defaultProps = {
  value: '',
  onChange: () => {},
  placeholder: "Search",
}

export default memo(SearchInput);