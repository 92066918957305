import css from 'styled-jsx/css'

const albumStyle = css`
  .album {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    touch-action: manipulation;
    outline-color: red;
  }

  .album.is-dragging {
    visibility: hidden;
  }

  .album .bookmark {
    position: absolute;
    right: 10px;
    top: -3px;
    color: RGBA(var(--accentColorDark), 1);
  }

  .album .preview {
    width: 100%;
    display: inline-block;
    background-color: white;
    border: 1px solid rgba(var(--border1), 1);
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .album .label {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 100%;
    color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    display: inline-block;
    flex-shrink: 0;
  }

  .album .label .normal {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .album .label .normal:last-child {
    height: 100%;
  }

  .album .label .smaller {
    font-size: 0.8em;
    opacity: 0.6;
    line-height: 100%;
  }
`;

const style = css`
  .folder-view {
    padding: 30px;
    position: relative;
    flex: 1 !important;
  }

  .folder-view :global(.row) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .folder-view :global(.folder-placeholder) {
    border: 1px solid rgba(var(--border1), 1);
    border-radius: 5px;
    box-sizing: border-box;
  }

  .folder-view :global(.album-placeholder) :global(.preview) {
    border: 1px solid rgba(var(--border1), 1);
    padding-bottom: 100%;
    width: 100%;
  }
`;

const folderStyle = css`
  .folder {
    border: 1px solid rgba(var(--border1), 1);
    box-sizing: border-box;
    border-radius: 5px;
    text-decoration: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    transform: translateZ(0);
    touch-action: manipulation;
  }

  .folder a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    height: 100%;
    box-sizing: border-box;
  }

  .folder a :global(svg) {
    color: rgba(var(--accentColorDark), 1);
    width: 32px;
  }

  .folder.is-dragging {
    visibility: hidden;
  }

  .folder .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 5px;
    font-size: 100%;
    color: rgba(0, 0, 0, 0.7);
  }
`;


export {
  albumStyle,
  folderStyle,
}
export default style;