import css from 'styled-jsx/css'

export default css`
  .folder-bar {
  position: relative;
  margin: 0 auto;
  margin-top: 40px;
}
.folder-bar .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
}

@media (max-width: 420px) {
  .folder-bar {
    margin-top: 0px;
  }
}

.folder-bar .back-button {
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: rgba(var(--accentColorDark), 1);
  position: relative;
  height: 45px;
}

@media (max-width: 420px) {
  .folder-bar .back-button {
    padding-right: 20px;
    padding-left: 20px;
    margin-left: -20px;
  }
}

.folder-bar .back-button .label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
}

@media (max-width: 420px) {
  .folder-bar .back-button .label {
    display: none;
  }
}

.folder-bar .round-button {
  -webkit-appearance: none;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  border: 0px;
  background-color: white;
  border: 1px solid rgba(var(--buttonBorder), 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  color: rgba(var(--accentColorDark), 1);
  cursor: pointer;
  outline: none;
  flex-shrink: 0;
}
.folder-bar .round-button:focus-within {
  border-color: rgba(var(--accentColor), 1);
  box-shadow: 0 0px 0px 2px rgba(var(--accentColorMedium), 1);
}

.folder-bar .round-button.with-label {
  width: auto;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
}
.folder-bar .round-button.with-label :global(svg) {
  margin-right: 5px;
}

.folder-bar .round-button:active {
  color: rgba(var(--accentColorDark), 1);
}

.folder-bar .round-button.accent {
  background-color: rgba(var(--accentColor), 1);
  border-color: rgba(var(--accentColor), 1);
  color: white;
}
.folder-bar .round-button.accent:active {
  color: white;
}

.folder-bar .round-button :global(svg) {
  width: 1.5rem;
  height: 1.5rem;
}

.folder-bar .round-button+.round-button {
  margin-left: 1rem;
}

.folder-bar .search-wrapper {
  margin-left: 10px;
  position: relative;
}

.round-button + :global(.search-input) {
  margin-left: 10px;
}

.folder-bar .search-wrapper .search-icon {
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--accentColorDark), 1);
}

.folder-bar .search-wrapper .round-search {
  border-radius: 40px;
  width: 175px;
  height: 40px;
  padding-left: 40px;
}

.folder-bar .search-wrapper.active .round-search {
  width: 375px;
}

.folder-bar .search-wrapper .round-search::placeholder {
  color: rgba(var(--accentColorDark), 1);
}

.folder-bar .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-right: 20px;
  min-width: 200px;
}

.folder-bar .left > div {
  display: flex;
}

.folder-bar .right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
}
@media (max-width: 420px) {
  .folder-bar .right {
    flex-grow: 1;
  }
}

.folder-bar h1 {
  color: rgba(var(--accentColorDark), 1);
  font-size: 6rem;
  line-height: 125%;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: inherit;
  display: inline-block;
  margin: 0;
  outline: none;
  max-width: 100%;
}

@media (max-width: 420px) {
  .folder-bar h1 {
    font-size: 4rem;
  }
}

.folder-bar h1.editable {
  cursor: text;
}

.folder-bar h1.editing {
  text-overflow: initial;
}
`;

export const backStyle = css`
  .back {
    display: inline-flex;
    align-items: center;
    color: rgba(var(--accentColorDark), 1);
    text-decoration: none;
    font-size: 15px;
    font-family: 'Source Sans Pro', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px 10px;
    border-radius: 50px;
    margin-left: -10px;
  }

  .back > div {
    line-height: 30px;
  }

  .back :global(svg) {
    margin-right: 5px;
  }
`;