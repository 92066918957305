import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import {backStyle} from './style';
import Link from "next/link";
import { useCallback, useRef, useState } from 'react';
import dnd from 'utils/DnD';
import { observer } from 'mobx-react';

function BackLink({isSearch, folder, parent}) {
  const dragAndDropAllowed = true;
  const [isOver, setIsOver] = useState(false);
  const dragTarget = useRef();

  const addDnD = useCallback((domNode) => {
    if(dragTarget.current)
      dragTarget.current.destroy();

    if(!domNode)
      return;
    
    dragTarget.current = dnd.addTarget('folder-item', domNode, {
      isOver: (state) => {
        setIsOver(state);
      },
      canDrop: () => {
        return dragAndDropAllowed;
      },
      drop: () => {
        const returnValue = {
          dropped: true,
          folder: parent,
        };

        return returnValue;
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const style = {
    backgroundColor: isOver ? 'rgba(var(--accentColorDark), 0.5)' : undefined,
  }

  if(!isSearch && !parent) {
    return <div className="back" ref={addDnD} style={style}><div>&nbsp;</div><style jsx>{backStyle}</style></div>;
  }

  if(isSearch) {
    return (
      <div>
        <Link
          href={
            folder.key === "root" ?
            `/myalbums` :
            `/folder/${folder.key}`
          }
          legacyBehavior>
          <a className="back" ref={addDnD} style={style}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <div>{folder.name}</div>
          </a>
        </Link>
        <style jsx>{backStyle}</style>
      </div>
    );
  }

  if(parent) {
    return (
      <div>
        <Link
          href={
            parent.key === "root" ?
            `/myalbums` :
            `/folder/${parent.key}`
          }
          legacyBehavior>
          <a className="back" ref={addDnD} style={style}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <div>{parent.name}</div>
          </a>
        </Link>
        <style jsx>{backStyle}</style>
      </div>
    );
  }

  return null;
}

export default observer(BackLink);