import isDev from "utils/isDev";
import AlbumModel from "./album-model";
import FolderModel from "./folder-model";

export default class FolderItemStore
{
  albums = new Map();
  folders = new Map();
  user = null;

  /** @type {Map<String, FolderModel} */
  models = new Map();

  constructor(user) {
    this.user = user;
  }

  getAlbum(parent, data)
  {
    if(!this.albums.has(data.key))
      this.albums.set(data.key, new AlbumModel(this, parent, data));

    return this.albums.get(data.key);
  }

  deleteAlbum(album)
  {
    this.albums.delete(album.key);
    this.models.forEach(model => model.contents.remove(album));
  }

  getFolder(data, parent, config)
  {
    this.folders.set(data.key, new FolderModel(this, data, parent, config));
    return this.folders.get(data.key);
  }

  deleteFolder(folder)
  {
    this.folders.delete(folder.key);
    this.models.forEach(model => model.contents.remove(folder));
  }

  /**
   * @param {FolderModel} model
   */
  registerModel(model)
  {
    this.models.set(model.key, model);
  }

  dispose()
  {
    if(isDev)
      console.debug("disposing contentstore");

    this.albums.clear();
    this.folders.clear();
  }

  getTrending(data) {
    return new FolderModel(this, {
      key: "trending",
      contents: data,
    }, null, {registerInStoreAsRoot: true});
  }

  getSearchResults(data)
  {
    return new FolderModel(this, {
      key: "search",
      contents: data,
    });
  }
}