import { useEffect, memo, useState } from "react";
import translationStore from 'stores/translation-store';
import { Modal } from "components/UI";
import { useUser } from "utils/user/lib";

function TrialPopup() {
  const [isMounted, setIsMounted] = useState(false);
  const user = useUser();
  const translate = translationStore.translate.bind(translationStore);
  const days = 7;

  useEffect(() => {
    // Alleen gratis accounts hiermee lastig vallen
    // Al trial gehad? dan ook geen promo meer
    if(isMounted || !user.isLoggedIn || user.subscription.type!=='basic' || user.flags.hasUsedTrial)
    {
      return function(){};
    }

    const seenMyalbumsTrialPopup = window.localStorage.getItem('seenTrialPopup');
    const seenMyalbums = window.sessionStorage.getItem('seenMyalbums');
    if(seenMyalbums)
    {
      const now = new Date();
      let showPopup = !seenMyalbumsTrialPopup; // geen waarde in localStorage dan tonen

      if(seenMyalbumsTrialPopup)
      {
        // Controleer of de popup een x aantal dagen geleden voor het laatst is getoond
        const lastSeenDate = new Date(Number(seenMyalbumsTrialPopup));
        const daysAgo = new Date(now - (days * 24 * 60 * 60 * 1000));
        if(lastSeenDate<=daysAgo) {
          // x aantal dagen geleden dus popup mag opnieuw getoond worden
          showPopup = true;
        }
      }

      if(showPopup) {
        window.localStorage.setItem('seenTrialPopup', new Date().getTime());

        Modal.Confirm({
          title: translate("trial.popup.title"),
          description: translate("trial.popup.text", {days: 30}),
          okButtonText: translate("trial.popup.okbuttontext"),
          cancelButtonText: translate("trial.popup.cancelbuttontext"),
        })
        .then(() => {
          location.href = process.env.SITE_URL + '/premium?redirect=1&ref=myalbums-trial-promo'
        })
        .catch(() => {})
      }
    }

    window.sessionStorage.setItem('seenMyalbums', 1);
    setIsMounted(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default memo(TrialPopup);