import { memo, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react'
import { faFolders } from "@fortawesome/pro-light-svg-icons/faFolders";
import { faRocketLaunch } from "@fortawesome/pro-light-svg-icons/faRocketLaunch";
import { faSignalStream } from "@fortawesome/pro-light-svg-icons/faSignalStream";
import Dom from '@myalbum/dom';
import { MenuBar, IconButton } from 'components/UI'
import translationStore from 'stores/translation-store';
import { useUser } from 'utils/user/lib';
import { useRouter } from 'next/router';

function MainMenuBar()
{
  const router = useRouter();
  const user = useUser();
  const streamsRef = useRef();
  const albumsRef = useRef();
  const [arrowPosition, setArrowPosition] = useState(0);

  useEffect(() => {
    function updateArrowPosition() {
      let node;
      switch(router.pathname.split("/").filter(x => x)[0]) {
        case 'myalbums':
        case 'folder':
          if(albumsRef.current) {
            node = Dom(albumsRef.current).absoluteLayout();
          }
          break;
        case 'mystreams':
          if(streamsRef.current) {
            node = Dom(streamsRef.current).absoluteLayout();
          }
          break;
      }
  
      if(node) {
        setArrowPosition(node.left + node.width / 2);
      }
    }
    
    window.addEventListener('resize', updateArrowPosition);
    updateArrowPosition();

    return () => {
      window.removeEventListener('resize', updateArrowPosition);
    }
  }, [router.pathname]);

  return (
    <Observer>
    {
      () =>
      {
        const subscriptionType = user.subscription.type;

        // Upgrade button
        let upgradeButton = null;
        if(
          subscriptionType==='premium' ||
          subscriptionType==='basic'
        ) {
          const link = subscriptionType==='premium' ? '/business?redirect=1&ref=header' : '/premium?redirect=1&ref=header';
          upgradeButton = (
            <IconButton
              label={translationStore.translate("base.upgrade")}
              icon={faRocketLaunch}
              iconSize={18}
              className="menu-bar-button"
              onClick={() => {
                const url = process.env.SITE_URL + link;
                location.href = url;
              }}
            />
          )
        }

        return (
          <MenuBar
            key="myalbums-menubar"
            arrowPosition={arrowPosition}
          >
            <div key="mobile">
              <div style={{width: 10}} />
              {
                user.features.streamBeta !== 'on' && (
                  <MenuBar.Logo />
                )
              }

              {
                user.features.streamBeta === 'on' && (
                  <>
                    <IconButton
                      icon={faSignalStream}
                      href="/mystreams/"
                      label="Streams"
                      className="menu-bar-button"
                    />

                    <IconButton
                      icon={faFolders}
                      href="/myalbums/"
                      label="Albums"
                      className="menu-bar-button"
                    />
                  </>
                )
              }

              <div style={{flex: 1}}></div>
              <MenuBar.Cart />
              <div style={{width: 10}} />
            </div>

            <div key="left">
            </div>

            <div key="center">
              {
                user.features.streamBeta !== 'on' && (
                  <MenuBar.Logo />
                )
              }

              {
                user.features.streamBeta === 'on' && (
                  <>
                    <IconButton
                      icon={faSignalStream}
                      href="/mystreams/"
                      label="Streams"
                      className="menu-bar-button"
                    />

                    <IconButton
                      icon={faFolders}
                      href="/myalbums/"
                      label="Albums"
                      className="menu-bar-button"
                    />
                  </>
                )
              }
            </div>

            <div key="right">
              {upgradeButton}
              <MenuBar.Cart />
            </div>

          </MenuBar>
        );
      }
    }
    </Observer>
  );
}

export default memo(MainMenuBar);