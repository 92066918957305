import { memo, useEffect, useState, useRef } from "react";
import { Observer } from "mobx-react";
import Album from 'components/Folder/View/Album';
import styles from './style';
import {timeSince} from 'utils/Utils';
import { reaction } from "mobx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";

/**
 * @param {Object} props
 * @param {import('models/contents/folder-model').default} props.folder
 */
function TrendingAlbumView(props) {
  const itemSize = props.itemSize;
  const labelSize = 44;
  const marginRight = 15;
  const ref = useRef();
  const [items, setItems] = useState(getItems());

  function getItems() {
    // Niet meer items tonen dan er in de breedte passen!
    if(!Number.isInteger(props.width))
    {
      // We moeten wel een breedte hebben
      return;
    }

    const maxWidth = props.width;
    const itemsPerRow = Math.min(props.maxItemsPerRow, Math.floor((maxWidth + marginRight) / (itemSize + marginRight)));

    if(props.folder)
      return props.folder.contents.slice(0, itemsPerRow - 1);
    else {
      return [];
    }
  }

  useEffect(() =>
  {
    setItems(getItems());

    const dispose = reaction(
      () => props.folder.contents.length,
      () =>
      {
        setItems(getItems());
      }
    )

    return () =>
    {
      dispose();
    }
  }, [props.width]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Observer>
    {
      () => (
        <div
          ref={ref}
          className="trending"
          style={{
            maxWidth: props.width,
            height: itemSize + labelSize,
          }}
        >
          <button
            className="new-album-placeholder"
            style={{
              width: props.itemSize,
              height: props.itemSize,
              marginRight,
            }}
            onClick={props.onCreateAlbum}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
          {
            props.itemSize>0 && items.map((item, index) => {
              const lastActivity = Date.parse(item.lastActivity);
              const since = (timeSince(lastActivity));
              const albumMargin = index<items.length-1 ? marginRight : 0;

              return <Album
                key={item.key}
                width={props.itemSize}
                height={props.itemSize+labelSize}
                labelSize={labelSize}
                style={{marginRight: albumMargin}}

                item={item}
                name2={since}
                url={`/album/${item.key}/`}
                onContextMenu={props.onContextMenu}
              />
            })
          }
          <style jsx>{styles}</style>
        </div>
      )
    }
    </Observer>
  );
}

TrendingAlbumView.defaultProps = {
  minItemsPerRow: 3,
  maxItemsPerRow: 8,
  onCreateAlbum: () => {},
}

export default memo(TrendingAlbumView);